import React from 'react'
import PropTypes from 'prop-types'

import { COLORS } from '../../identity/colors'
import Text from '../text/text'

import { Field } from 'formik'

// missing styling
const RadioButton = ({ children, field, form, id, innerRef }) => (
	<Text as="label" htmlFor={id} size="16" color={COLORS.GRAPHITE}>
		<input
			{...field}
			ref={innerRef}
			type="radio"
			id={id}
			value={id}
			checked={id === field.value}
			onChange={field.onChange}
			onBlur={field.onBlur}
		/>

		{children}
	</Text>
)

const RadioButtonField = React.forwardRef((props, ref) => (
	<Field {...props} component={RadioButton} innerRef={ref} />
))

RadioButtonField.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired, //parent id
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	validate: PropTypes.func,
	// html
	autofocus: PropTypes.bool,
	disabled: PropTypes.bool
}

const RadioGroup = props => <fieldset {...props}>{props.children}</fieldset>

RadioGroup.propTypes = {
	children: PropTypes.node.isRequired,
	id: PropTypes.string.isRequired
}

export { RadioButtonField as RadioButton, RadioGroup }
