import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './carousel.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

class Carousel extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			currentIndex: 0
		}
	}

	componentDidMount() {
		this.disableAnimationTimer = window.setTimeout(() => {
			this.goToNextSlide()
		}, 5000)
	}

	componentDidUpdate(prevState) {
		if (this.state.currentIndex !== prevState.currentIndex) {
			window.clearTimeout(this.disableAnimationTimer)
			this.disableAnimationTimer = window.setTimeout(() => {
				this.goToNextSlide()
			}, 5000)
		}
	}

	componentWillUnmount() {
		window.clearTimeout(this.disableAnimationTimer)
	}

	goToNextSlide = () => {
		this.setState(prevState => {
			if (prevState.currentIndex + 1 > this.props.children.length - 1) {
				return {
					currentIndex: 0
				}
			}
			return {
				currentIndex: prevState.currentIndex + 1
			}
		})
	}

	stopRotation = () => {
		window.clearTimeout(this.disableAnimationTimer)
		this.disableAnimationTimer = null
	}

	startRotation = () => {
		this.disableAnimationTimer = window.setTimeout(() => {
			this.goToNextSlide()
		}, 5000)
	}

	render() {
		return (
			<section
				className={classnames(styles.CarouselWrapper, this.props.className)}
				aria-roledescription="carousel"
				aria-label={this.props.ariaLabel}
				aria-live={this.disableAnimationTimer ? 'polite' : 'off'}
				onFocus={this.stopRotation}
				onBlur={this.startRotation}
				onMouseOver={this.stopRotation}
				onMouseLeave={this.startRotation}
			>
				{this.props.children.map((slide, index) => (
					<div
						className={classnames(styles.CarouselSlide, this.props.slideClassName, {
							[styles.HiddenSlide]: this.state.currentIndex !== index,
							[styles.ShownSlide]: this.state.currentIndex === index
						})}
						key={index}
						role="group"
						aria-roledescription="slide"
						aria-label={`${index + 1} of ${this.props.children.length}`}
					>
						{slide}
					</div>
				))}
			</section>
		)
	}
}

Carousel.defaultProps = {
	slideClassName: '',
	className: ''
}

Carousel.propTypes = {
	children: PropTypes.array.isRequired,
	ariaLabel: PropTypes.string.isRequired,
	slideClassName: PropTypes.string,
	className: PropTypes.string
}

export default Carousel
