import React from 'react'
import PropTypes from 'prop-types'

import Button from '../button/button'

const SubmitButton = props => <Button {...props} type="submit" />

SubmitButton.propTypes = {
	htmlFor: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired
}

export default SubmitButton
