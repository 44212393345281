/* ====================================================== */
/*                     Public API                         */
/* ====================================================== */

const formatDate = dateToParse => {
	const date = new Date(dateToParse)
	const options = { weekday: undefined, year: 'numeric', month: 'short', day: 'numeric' }
	const dateStingified = date.toLocaleDateString('en-US', options)

	return dateStingified
}

export default formatDate
