import React from 'react'
import PropTypes from 'prop-types'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import { Formik, Form as FormikForm } from 'formik'

import InputElement, { validateEmail } from './input_element'
import Input from './input'
import { RadioButton, RadioGroup } from './radio_button'
import { Checkbox } from './checkbox_input'
import ErrorMessage, { ERROR_MESSAGES } from './error_message'
import Label from './label'
import SubmitButton from './submit_button'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const Form = props => {
	const { render, className } = props
	return (
		<Formik
			{...props}
			render={renderProps => <FormikForm className={className}>{render(renderProps)}</FormikForm>}
		/>
	)
}

Form.propTypes = {
	render: PropTypes.func.isRequired,
	enableReinitialize: PropTypes.bool,
	initialValues: PropTypes.object.isRequired,
	onReset: PropTypes.func,
	onSubmit: PropTypes.func.isRequired,
	validate: PropTypes.func,
	validateOnBlur: PropTypes.bool,
	validateOnChange: PropTypes.bool,
	validationSchema: PropTypes.func,
	className: PropTypes.string,
	// HTML
	autocomplete: PropTypes.bool
}

export {
	Form,
	Input,
	InputElement,
	RadioButton,
	RadioGroup,
	Checkbox,
	ErrorMessage,
	Label,
	SubmitButton,
	validateEmail,
	ERROR_MESSAGES
}
