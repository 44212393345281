import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import InputElement from './input_element'
import Label from './label'
import ErrorMessage from './error_message'

import styles from './input.module.scss'

const Input = props => (
	<div
		className={classnames(styles.InputRowWrapper, props.className, {
			[styles.LargeInputRow]: props.large,
			[styles.InputWithoutValue]: !props.value
		})}
	>
		<Label
			htmlFor={props.name}
			className={styles.Label}
			error={!!props.error && props.touched}
			large={props.large}
		>
			{props.label}
		</Label>
		<InputElement
			{...props}
			ariaLabel={props.label}
			className={styles.InputElement}
			id={props.name}
			placeholder={props.label}
			error={!!props.error && props.touched}
		/>
		<ErrorMessage name={props.name} className={styles.ErrorMessage} large={props.large} />
	</div>
)

Input.propTypes = {
	label: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	value: PropTypes.string,
	touched: PropTypes.bool,
	// html
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	// style
	large: PropTypes.bool,
	className: PropTypes.string
}

Input.defaultProps = {
	autoComplete: 'on',
	autoFocus: false,
	disabled: false,
	large: false,
	className: ''
}

export default Input
