import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'formik'

// missing styling
const Checkbox = ({ children, field, form, id, innerRef }) => (
	<div>
		<input
			{...field}
			ref={innerRef}
			type="checkbox"
			id={field.name}
			checked={field.value}
			onChange={field.onChange}
			onBlur={field.onBlur}
		/>
		<label htmlFor={field.name}>{children}</label>
	</div>
)

const CheckboxField = React.forwardRef((props, ref) => (
	<Field {...props} component={Checkbox} innerRef={ref} />
))

CheckboxField.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.string.isRequired, //parent id
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	validate: PropTypes.func,
	// html
	autofocus: PropTypes.bool,
	disabled: PropTypes.bool
}

export { CheckboxField as Checkbox }
