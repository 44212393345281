import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import routes from '../../../routes'

import { COLORS } from '../../identity/colors'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import Text from '../../elements/text/text'
import TextLink from '../../elements/text_link/text_link'
import { Form, Input, SubmitButton, validateEmail, ERROR_MESSAGES } from '../../elements/form/form'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './newsletter_subscription.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const FORM_MESSAGES = {
	SUCCESS: 'Thanks for subscribing!',
	ERROR: 'Hmm, something went wrong. Could you try again or use a different email address? Thanks!'
}

const NewsletterForm = ({ className, compact }) => {
	// const [mailChimpMessage, setMailChimpMessage] = useState({ error: false, message: '' })

	const handleSubmit = (values, { setSubmitting }) => {
		// if (process.env.NODE_ENV === 'production') {
		// 	return addToMailchimp(values.email, { ...values })
		// 		.then(data => {
		// 			setMailChimpMessage(
		// 				data.result === 'success'
		// 					? { error: false, message: FORM_MESSAGES.SUCCESS }
		// 					: { error: true, message: FORM_MESSAGES.ERROR }
		// 			)
		// 			setSubmitting(false)
		// 		})
		// 		.catch(() => {
		// 			setMailChimpMessage({ error: true, message: FORM_MESSAGES.ERROR })
		// 			setSubmitting(false)
		// 		})
		// } else {
		// 	setMailChimpMessage({ error: true, message: 'NOT AVAILABLE IN DEVELOPMENT MODE' })
		// 	setSubmitting(false)
		// }
	}

	const formClasses = classnames(styles.Form, {
		[styles.Compact]: compact
	})

	const formInnerClasses = classnames(styles.FormInner, {
		[styles.Compact]: compact
	})

	const inputClasses = classnames(styles.Input, {
		[styles.Compact]: compact
	})

	return (
		<div className={classnames(styles.Newsletter, className)}>
			<Text as="h1" size="36" chunky>
				Don't miss a thing
			</Text>
			{!compact && (
				<Text as="p" size="18" className={styles.Description}>
					Subscribe to our newsletter and stay up-to-date on everything edtech, 21st-century teaching tips and
					free video resources for your classes!
				</Text>
			)}
			<Form
				className={formClasses}
				initialValues={{ email: '' }}
				validate={values => {
					let errors = {}
					if (!values.email) {
						errors.email = ERROR_MESSAGES.REQUIRED
					}
					return errors
				}}
				onSubmit={handleSubmit}
				id="newsletterForm"
				render={({ values, errors, touched, isSubmitting, isValid }) => (
					<React.Fragment>
						<div className={formInnerClasses}>
							<Input
								type="email"
								name="email"
								required
								label="Your email"
								error={errors.email}
								value={values.email}
								touched={touched.email}
								validate={validateEmail}
								className={inputClasses}
							/>

							<SubmitButton
								disabled={isSubmitting}
								className={styles.SubmitButton}
								htmlFor="newsletterForm"
								value="Subscribe"
							>
								Subscribe
							</SubmitButton>
						</div>

						<Text as="p" size="14" className={styles.LegalDisclaimer}>
							By subscribing, you agree to our{' '}
							<TextLink size="14" color={COLORS.AZURE} target="_blank" to={routes.legalDisclaimer()}>
								Legal Disclaimer
							</TextLink>
							.
						</Text>

						{/* {mailChimpMessage.message ? (
							<Text
								as="p"
								size="18"
								color={mailChimpMessage.error ? COLORS.CRIMSON : COLORS.JUNGLE}
								className={styles.MailChimpMessage}
							>
								{mailChimpMessage.message}
							</Text>
						) : null} */}
					</React.Fragment>
				)}
			/>
		</div>
	)
}

NewsletterForm.propTypes = {
	className: PropTypes.string,
	compact: PropTypes.bool
}

NewsletterForm.defaultProps = {
	className: '',
	compact: false
}

export default NewsletterForm
