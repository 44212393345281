import React from 'react'
import PropTypes from 'prop-types'

import Text from '../text/text'
import { COLORS } from '../../identity/colors'

const Label = ({ htmlFor, large, error, className, children }) => (
	<Text
		htmlFor={htmlFor}
		color={error ? COLORS.CRIMSON : COLORS.ASHES}
		size={large ? '12' : '10'}
		code
		as="label"
		className={className}
	>
		{children.toUpperCase()}
	</Text>
)

Label.propTypes = {
	htmlFor: PropTypes.string.isRequired,
	large: PropTypes.bool,
	error: PropTypes.bool,
	className: PropTypes.string
}
Label.defaultProps = {
	large: false,
	error: false,
	className: ''
}

export default Label
