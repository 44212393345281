import React from 'react'
import { graphql } from 'gatsby'
import classnames from 'classnames'
import routes from '../routes'

import detectColor from '../ui/identity/colors'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import { Link } from 'gatsby'
import Layout from '../ui/layout/layout'
import Image from '../ui/elements/image/image'
import Text from '../ui/elements/text/text'
import Button from '../ui/elements/button/button'
import TextLink from '../ui/elements/text_link/text_link'
import SEO from '../ui/components/seo/seo'
import Carousel from '../ui/components/carousel/carousel'
import Newsletter from '../ui/components/newsletter_subscription/newsletter_subscription'
import ArticleInformation from '../ui/components/article_information/article_information'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './index.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const HomePage = ({ data, location }) => (
	<Layout location={location} headerFullBackground>
		<SEO />
		<div className={styles.PageWrapper}>
			<FeaturedPostsSection featuredPost={data.featured.featuresPost} />

			{data.categories.edges.length > 0 &&
				data.posts.edges.length > 0 &&
				data.categories.edges.map(({ node }, i) => {
					const postOfCategory = data.posts.edges.filter(postNode => {
						return postNode.node.category.id === node.id
					})

					if (!postOfCategory.length) {
						return null
					}
					switch (i) {
						case 0:
							return (
								<CategorySection {...node} className={styles.FirstCategory} key={i}>
									<FirstCategory posts={postOfCategory} />
								</CategorySection>
							)
						case 1:
							return (
								<CategorySection {...node} key={i}>
									<SecondCategory posts={postOfCategory} />
								</CategorySection>
							)
						case 2:
							return (
								<CategorySection {...node} key={i}>
									<ThirdCategory posts={postOfCategory} />
								</CategorySection>
							)
						default:
							return (
								<CategorySection {...node} key={i}>
									<FourthCategory posts={postOfCategory} />
								</CategorySection>
							)
					}
				})}
			{/* <section title={`Newsletter subscription`} className={styles.NewsletterSection}>
				<Newsletter />
			</section> */}
		</div>
	</Layout>
)

const FeaturedPostsSection = ({ featuredPost }) => (
	<Carousel
		className={styles.FeaturedPostsContainer}
		slideClassName={styles.FeaturedPostsSlide}
		ariaLabel="Featured posts"
	>
		{featuredPost.length > 0 && featuredPost.map((post, index) => <FeaturedPost key={index} post={post} />)}
	</Carousel>
)

const FeaturedPost = ({ post }) => {
	return (
		<React.Fragment>
			<div className={styles.FeaturedPostImageContainer}>
				<div className={styles.HeroImage}>
					<Link tabIndex="-1" to={routes.post({ categorySlug: post.category.slug, postSlug: post.slug })}>
						<Image
							fluid={post.heroImage.fluid}
							wrapperClassName={styles.HeroImageInner}
							alt={post.heroImage.description}
						/>
					</Link>
				</div>
			</div>
			<div className={styles.FeaturedPostTextContainer}>
				<TextLink tag as="link" size="18" block to={routes.category({ slug: post.category.slug })}>
					{post.category.title.toUpperCase()}
				</TextLink>
				<TextLink
					as="link"
					size="56"
					chunky
					block
					to={routes.post({ postSlug: post.slug, categorySlug: post.category.slug })}
					className={styles.FeaturedPostTitle}
				>
					{post.title}
				</TextLink>
				<Text>{post.description}</Text>
			</div>
		</React.Fragment>
	)
}

const CategorySection = ({ color, title, slug, children, className }) => (
	<section title={`${title} category`} className={styles.CategorySection}>
		<span className={classnames(styles.Sidebar, styles[`${detectColor(color)}`])} />
		<div className={classnames(styles.CategoryContainer, className)}>
			<div className={styles.CategoryInnerWrapper}>
				<Text as="h1" className={styles.CategoryTitle}>
					<TextLink as="link" tag size="18" to={routes.category({ slug })} color={color}>
						{title.toUpperCase()}
					</TextLink>
				</Text>
				<div className={styles.CategoryArticlesContainer}>{children}</div>
				<Button as="link" secondary to={routes.category({ slug })} block className={styles.ViewAllButton}>
					View all
				</Button>
			</div>
		</div>
	</section>
)

const FirstCategory = ({ posts }) => (
	<div className={styles.FirstCategoryContainer}>
		<div className={styles.FirstCategoryLeftSide}>
			<Link
				tabIndex="-1"
				to={routes.post({ categorySlug: posts[0].node.category.slug, postSlug: posts[0].node.slug })}
			>
				<Image
					fluid={posts[0].node.heroImage.fluid}
					wrapperClassName={styles.FirstCategoryHeroImage}
					alt={posts[0].node.heroImage.description}
				/>
			</Link>
		</div>
		<div className={styles.FirstCategoryRightSide}>
			{posts.slice(0, 3).map(({ node }, index) => (
				<React.Fragment key={index}>
					<div className={styles.FirstCategoryArticleInfo}>
						<ArticleInformation {...node} key={index} />
					</div>
					{index < 2 ? <hr className={styles.FirstCategoryHR} /> : null}
				</React.Fragment>
			))}
		</div>
	</div>
)

const SecondCategory = ({ posts }) => (
	<div className={styles.SecondCategoryContainer}>
		{posts.slice(0, 4).map(({ node }, index) => (
			<div className={styles.SecondCategoryArticleContainer} key={index}>
				<Link tabIndex="-1" to={routes.post({ categorySlug: node.category.slug, postSlug: node.slug })}>
					<Image
						fluid={node.heroImage.fluid}
						wrapperClassName={styles.SecondCategoryHeroImage}
						alt={node.heroImage.description}
						key={index}
					/>
				</Link>
				<div className={styles.SecondCategoryArticleInfo}>
					<ArticleInformation {...node} key={index} />
				</div>
			</div>
		))}
	</div>
)

const ThirdCategory = ({ posts }) => (
	<div className={styles.ThirdCategoryContainer}>
		{posts.slice(0, 5).map(({ node }, index) => (
			<div className={styles.ThirdCategoryArticleContainer} key={index}>
				<Link tabIndex="-1" to={routes.post({ categorySlug: node.category.slug, postSlug: node.slug })}>
					<Image
						fluid={node.heroImage.fluid}
						wrapperClassName={styles.ThirdCategoryHeroImage}
						alt={node.heroImage.description}
						key={index}
					/>
				</Link>
				<div className={styles.ThirdCategoryArticleInfo}>
					<ArticleInformation {...node} key={index} />
				</div>
			</div>
		))}
	</div>
)

const FourthCategory = ({ posts }) => (
	<div className={styles.FourthCategoryContainer}>
		{posts.slice(0, 4).map(({ node }, index) => (
			<div className={styles.FourthCategoryArticleContainer} key={index}>
				<Link tabIndex="-1" to={routes.post({ categorySlug: node.category.slug, postSlug: node.slug })}>
					<Image
						fluid={node.heroImage.fluid}
						wrapperClassName={styles.FourthCategoryHeroImage}
						alt={node.heroImage.description}
						key={index}
					/>
				</Link>
				<div className={styles.FourthCategoryArticleInfo}>
					<ArticleInformation {...node} key={index} />
				</div>
			</div>
		))}
	</div>
)

export default HomePage

export const query = graphql`
	{
		categories: allContentfulCategory(sort: { fields: [orderNumber] }) {
			edges {
				node {
					id
					title
					slug
					color
				}
			}
		}
		posts: allContentfulPost(limit: 100, sort: { fields: [publishDate], order: DESC }) {
			edges {
				node {
					title
					slug
					publishDate
					minutesReading
					description
					category {
						id
						slug
					}
					heroImage {
						fluid(maxWidth: 881, maxHeight: 660) {
							...GatsbyContentfulFluid
						}
						description
					}
				}
			}
		}
		featured: contentfulFeaturedPosts {
			featuresPost {
				title
				slug
				description
				category {
					title
					slug
				}
				heroImage {
					fluid(maxWidth: 1329, maxHeight: 886) {
						...GatsbyContentfulFluid
					}
					description
				}
			}
		}
	}
`
