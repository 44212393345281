import React from 'react'
import PropTypes from 'prop-types'

import { ErrorMessage as FormikErrorMessage } from 'formik'
import { COLORS } from '../../identity/colors'

import Text from '../text/text'

export const ERROR_MESSAGES = {
	REQUIRED: 'This is a required field',
	INVALID_EMAIL: 'Invalid email address'
}

const ErrorMessage = ({ name, large, className }) => (
	<FormikErrorMessage
		color={COLORS.CRIMSON}
		size={large ? '12' : '10'}
		as="span"
		name={name}
		className={className}
		component={Text}
	/>
)

ErrorMessage.propTypes = {
	name: PropTypes.string.isRequired,
	large: PropTypes.bool,
	className: PropTypes.string
}

ErrorMessage.defaultProps = {
	large: false,
	className: ''
}

export default ErrorMessage
