import React from 'react'
import PropTypes from 'prop-types'
import routes from '../../../routes'
import formatDate from '../../helpers/date_formatter'

import { COLORS } from '../../identity/colors'

/* ====================================================== */
/*                       Components                       */
/* ====================================================== */

import { Link } from 'gatsby'
import Text from '../../elements/text/text'
import TextLink from '../../elements/text_link/text_link'

/* ====================================================== */
/*                         Styles                         */
/* ====================================================== */

import styles from './article_information.module.scss'

/* ====================================================== */
/*                    Implementation                      */
/* ====================================================== */

const ArticleInformation = ({ title, slug, publishDate, minutesReading, description, category }) => (
	<React.Fragment>
		<Text as="p" code size="12" color={COLORS.ASHES}>
			{`${formatDate(publishDate)} - ${minutesReading}' read`.toUpperCase()}
		</Text>
		<Text as="h2" color={COLORS.GRAPHITE} className={styles.Title}>
			<TextLink size="18" chunky to={routes.post({ categorySlug: category.slug, postSlug: slug })}>
				{title}
			</TextLink>
		</Text>
		<Link tabIndex="-1" to={routes.post({ categorySlug: category.slug, postSlug: slug })}>
			<Text as="p" size="16" color={COLORS.ASHES}>
				{description}
			</Text>
		</Link>
	</React.Fragment>
)

ArticleInformation.propTypes = {
	title: PropTypes.string.isRequired,
	slug: PropTypes.string.isRequired,
	publishDate: PropTypes.string.isRequired,
	minutesReading: PropTypes.number.isRequired,
	description: PropTypes.string.isRequired,
	category: PropTypes.shape({
		slug: PropTypes.string.isRequired
	})
}

export default ArticleInformation
