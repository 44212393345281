import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Field } from 'formik'
import { ERROR_MESSAGES } from './error_message'

import styles from './input.module.scss'

const Input = ({
	type,
	name,
	id,
	ariaLabel,
	value,
	required,
	className,
	large,
	placeholder,
	error,
	onInvalid,
	onChange,
	onBlur,
	validate,
	autoFocus,
	autoComplete,
	disabled
}) => (
	<Field
		type={type}
		id={id || name}
		name={name}
		aria-label={ariaLabel}
		required={required}
		placeholder={placeholder}
		value={value}
		component="input"
		className={classnames(styles.BaseInput, className, {
			[styles.Error]: error,
			[styles.Large]: large
		})}
		onInvalid={onInvalid}
		validate={validate}
		autoFocus={autoFocus}
		autoComplete={autoComplete}
		disabled={disabled}
	/>
)

Input.propTypes = {
	name: PropTypes.string.isRequired,
	id: PropTypes.string,
	ariaLabel: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	validate: PropTypes.func,
	// html
	autoComplete: PropTypes.string,
	autoFocus: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.string, //text, password, email, search, tel, url
	placeholder: PropTypes.string,
	// style
	large: PropTypes.bool,
	error: PropTypes.bool,
	className: PropTypes.string
}

Input.defaultProps = {
	autoComplete: 'on',
	autoFocus: false,
	disabled: false,
	type: 'text',
	placeholder: '',
	large: false,
	error: false,
	className: ''
}

export const validateEmail = value => {
	let errorMessage
	if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) {
		errorMessage = ERROR_MESSAGES.INVALID_EMAIL
	}
	return errorMessage
}

export default Input
